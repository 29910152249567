import React, { useState } from "react";
import { api } from "src/api";
import { AppUserDTO2 } from "src/backend";

interface UserBorrowerProps {
    skip: boolean;
}

export const useSearchUsers = (props: UserBorrowerProps) => {
    const [loading, setLoading] = useState(false);
    const [borrowers, setBorrowers] = useState<AppUserDTO2[]>([]);

    React.useEffect(() => {
        if (!props.skip) {
            (async () => {
                setLoading(true);
                const result = await api.searchUsers({ slice: '' });
                setBorrowers(result);
                setLoading(false);
            })()
        }
    }, [props.skip]);

    return { borrowers, loading };
}