import React from "react";
import { FormElementV2RequestDto, FormElementV2ResponseDto, StorageType } from "src/backend";
import { packageApi } from "src/services/packageApi";
import { createFormElements, deleteFormElements, getEntityElementsList, getEntityElementsTree, updateFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { sortFromElements } from "src/utils/form-element-transformer";

import { useGetElements } from "./use-get-elements";

interface V2FormElementsProps {
    loanId: string;
    entityId?: string;
}

export const useV2FormElements = (props: V2FormElementsProps) => {
    const elementsState = useGetElements({ loanId: props.loanId });

    const entityFormElementsTree = getEntityElementsTree(elementsState.list, props.entityId);
    const entityElements = getEntityElementsList(elementsState.list, props.entityId);

    const fileRequests = [...(props.entityId ? entityElements : elementsState.list)]
        .filter(e => !props.entityId || (e.sherpaEntityId === props.entityId))
        .filter(e => e.storageType === StorageType.FILE);

    const totalFileRequests = fileRequests.length;
    const totalHiddenFileRequests = fileRequests.filter(e => !!e.hidden).length;
    const ids = React.useMemo(() => {
        // @ts-ignore
        return [...elementsState.list].sort(sortFromElements).map((formElement) => formElement.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementsState.list.length])

    const expandedEntityIds = React.useMemo(() => {
        return entityFormElementsTree.map((formElement) => formElement.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityFormElementsTree.length])

    const dispatch = useDispatch();

    const refreshElements = React.useCallback(() => {
        if (!!props.loanId) {
            dispatch(packageApi.util.invalidateTags([{
                type: "FormElementsV2ResponseDto",
                id: props.loanId
            }]));
        }
    }, [dispatch, props.loanId]);


    const handleDelete = async (formElement: FormElementV2ResponseDto) => {
        await dispatch(deleteFormElements({
            multiSelect: false,
            loanId: props.loanId,
            formElementIds: [formElement.id],
        }))
        refreshElements();
    }

    const handleAdd = async (formElement: Partial<FormElementV2RequestDto>) => {
        await dispatch(createFormElements({
            formElements: [formElement],
            loanId: props.loanId,
        }))
        refreshElements();
    }

    const handleUpdate = async (formElement: Partial<FormElementV2ResponseDto>) => {
        await dispatch(updateFormElements({
            multiSelect: false,
            formElements: [formElement].map((formElement) => ({
                ...formElement,
                loanId: props.loanId,
            })),
            loanId: props.loanId,
        }))
        refreshElements();
    }
    return {
        formElementsTree: typeof props.entityId !== 'undefined' ? entityFormElementsTree : elementsState.tree,
        onDelete: handleDelete,
        onAdd: handleAdd,
        onUpdate: handleUpdate,
        ids: typeof props.entityId !== 'undefined' ? expandedEntityIds : ids,
        expandedEntityIds,
        totalFileRequests,
        totalHiddenFileRequests
    } as const
}