import { j as o } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as h, useCallback as k, useImperativeHandle as C } from "react";
import { u as F } from "./index-Ce_xd5Ya.js";
import { D as w } from "./DropHere.component-V7Q9C4Mn.js";
import { c as A } from "./utils-CJ9afRe1.js";
const H = {
  "image/jpg": [".jpg"],
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff"],
  "image/heic": [".heic"],
  "video/mp4": [".mp4"],
  "video/avi": [".avi"],
  "video/mov": [".mov"],
  "video/flv": [".flv"],
  "video/mkv": [".mkv"],
  "video/m4v": [".m4v"],
  "video/mpeg": [".mpeg"],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".odt",
    ".docx",
    ".doc",
    ".dotx",
    ".dot"
  ],
  // 'application/msword': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.ms-excel': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".ppsx",
    ".pptm",
    ".pot",
    ".potm",
    ".ppsx",
    ".pps",
    ".pptx",
    ".ppt"
  ],
  // 'application/vnd.ms-powerpoint': ['.ppt', '.pptx', '.potx', '.pot'],
  "application/vnd.ms-excel": [".xls", ".xlsb", ".xlt", ".xltm", ".xltx", ".xlsx", ".csv", ".xlsm"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xls", ".xlsb", ".xlt", ".xltm", ".xltx", ".xlsx", ".csv", ".xlsm"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "application/json": [".json"],
  "application/xml": [".xml"],
  "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp", ".bmp", ".tiff", ".heic"],
  "video/*": [".mp4", ".avi", ".mov", ".flv", ".mkv", ".m4v", ".mpeg"]
  // 'application/pdf': ['.pdf'],
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/msword': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.ms-excel': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx', '.ppt', '.potx', '.pot'],
  // 'application/vnd.ms-powerpoint': ['.ppt', '.pptx', '.potx', '.pot'],
  // 'application/zip': ['.zip'],
  // 'application/x-rar-compressed': ['.rar'],
  // 'text/plain': ['.txt'],
  // 'text/csv': ['.csv'],
  // 'application/json': ['.json'],
  // 'application/xml': ['.xml'],
}, m = h((p, n) => {
  const { onFilesDrop: s, activeClassName: c, as: d, hideDropHere: f, multiple: g = !1, error: r = !1, disabled: t = !1, children: x, ...l } = p, v = d ?? "div", u = k(
    (i, D, a) => {
      a == null || a.stopPropagation(), s(i, D.map((j) => j.file));
    },
    [s]
  ), e = F({
    // noDragEventsBubbling: true,
    accept: p.accept ?? H,
    onDrop: u,
    multiple: g,
    disabled: t
  }), b = (i) => {
    i.preventDefault(), i.target instanceof HTMLElement && i.target.getAttribute("data-upload-trigger") && e.open();
  };
  return C(n, () => ({
    openFilePicker: () => e.open()
  }), [e]), /* @__PURE__ */ o.jsxs(
    v,
    {
      "aria-invalid": r,
      "aria-disabled": t,
      "aria-label": "Drop files here",
      ...l,
      className: A(
        "relative",
        l.className,
        e.isDragActive && c,
        {
          "is-dragover [&_.is-drag-active]:flex": e.isDragActive,
          "border-destructive": r
        }
      ),
      ...e.getRootProps(),
      onClick: t ? void 0 : b,
      children: [
        /* @__PURE__ */ o.jsx("input", { role: "button", "data-testid": "upload-file-input", ...e.getInputProps(), "aria-label": "Upload file" }),
        /* @__PURE__ */ o.jsx(w, { hideText: f, active: e.isDragActive }),
        x
      ]
    }
  );
});
m.displayName = "FilesDropContainer";
m.DropTargetAttributes = { "data-upload-trigger": "true" };
export {
  m as F
};
