import { LoanReviewStatus } from "src/backend";

/**
 * 
 * @param status  
 * IN_PROGRESS = "IN_PROGRESS",
    READY_FOR_UNDERWRITING = "READY_FOR_UNDERWRITING",
    READY_FOR_REVIEW_PENDING = "READY_FOR_REVIEW_PENDING",
    READY_FOR_REVIEW_REVIEW = "READY_FOR_REVIEW_REVIEW",
    REVIEW_COMPLETE_ACCEPTED = "REVIEW_COMPLETE_ACCEPTED",
    REVIEW_COMPLETE_CONDITIONAL = "REVIEW_COMPLETE_CONDITIONAL",
    REVIEW_COMPLETE_REJECTED = "REVIEW_COMPLETE_REJECTED",
    CLOSING = "CLOSING",
    HOLD = "HOLD",
    IN_SERVICE = "IN_SERVICE",
    WITHDRAWN = "WITHDRAWN",
 */
export const getReviewStatusChipLabel = (status: LoanReviewStatus) => {
    switch (status) {
        case LoanReviewStatus.IN_PROGRESS:
            return "In Progress";
        case LoanReviewStatus.READY_FOR_UNDERWRITING:
            return "Underwriting";
        case LoanReviewStatus.READY_FOR_REVIEW_PENDING:
            return "Pending";
        case LoanReviewStatus.READY_FOR_REVIEW_REVIEW:
            return "In Review";
        case LoanReviewStatus.REVIEW_COMPLETE_ACCEPTED:
            return "Accepted";
        case LoanReviewStatus.REVIEW_COMPLETE_CONDITIONAL:
            return "Conditional";
        case LoanReviewStatus.REVIEW_COMPLETE_REJECTED:
            return "Rejected";
        case LoanReviewStatus.CLOSING:
            return "Closing";
        case LoanReviewStatus.HOLD:
            return "Hold";
        case LoanReviewStatus.LEAD:
            return "Lead";
        case LoanReviewStatus.IN_DEFAULT:
            return "In Default";
        case LoanReviewStatus.ARCHIVE:
            return "Archived";
        case LoanReviewStatus.IN_SERVICE:
            return "Servicing";
        case LoanReviewStatus.WITHDRAWN:
            return "Withdrawn";
        default:
            return "";
    }
}