import Dialog from 'components/dist/atoms/Dialog';
import Separator from 'components/dist/atoms/Separator';
import { FormElementV2RequestDto, FormElementV2ResponseDto, Role } from 'src/backend';
import { StorageType } from 'src/constants/template';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useUser } from 'src/hooks/use-user';
import { useV2FormElements } from 'src/hooks/use-v2-form-elements';

import { FormElementForm } from '../v2/form-elements/form-element-form';

interface FormElementDialogProps {
  open: boolean;
  onClose: () => void;
  loanId: string;
  formElement?: Partial<FormElementV2ResponseDto>;
}


export const FormElementDialog = (props: FormElementDialogProps) => {
  const { onAdd, onUpdate } = useV2FormElements({ loanId: props.loanId });
  const { loan } = useActiveLoan();
  const { loanRole } = useUser({
    loan
  });

  const handleAddFormElement = (formElement: Partial<FormElementV2RequestDto>) => {
    if (formElement.id) {
      // if loanRole is underwriter only send the expiration date and hasExpiration
      if (loanRole?.role === Role.UNDERWRITER_LENDER) {
        onUpdate({
          id: formElement.id,
          hasExpiration: formElement.hasExpiration,
          expireDate: formElement.expireDate,
          title: formElement.title,
          approved: null,
          rejected: null
        })
      } else {
        onUpdate({
          ...formElement,
          parentId: null,
          approved: null,
          rejected: null
        });
      }
    } else {
      onAdd(formElement);
    }
    props.onClose();
  };

  const dialogFormElement = {
    id: props.formElement?.id,
    loanId: props.loanId,
    title: props.formElement?.title,
    parentId: props.formElement?.parentId,
    storageType: props.formElement?.storageType || StorageType.FILE,
    hasExpiration: props.formElement?.canHaveExpiration || false,
    canHaveExpiration: props.formElement?.canHaveExpiration || false,
    approved: props.formElement?.approved || null,
    fileName: props.formElement?.answer?.document?.name,
    hasChildren: props.formElement?.childrenIds?.length > 0,
  }

  let title = props.formElement?.storageType === StorageType.FILE ? `Create file request` : 'Create folder';
  if (props.formElement?.id) {
    title = `Rename`
  }
  if (props.formElement?.canHaveExpiration && props.formElement?.approved) {
    title = `Set expiration date for ${props.formElement.title}`
  }

  return (
    <Dialog open={props.open} onOpenChange={(open: boolean) => !open && props.onClose()}>
      <Dialog.Content className="gap-0">
        <Dialog.Header className="p-4">
          {title}
        </Dialog.Header>
        <Separator />
        <div className="px-4 pb-4">
          <FormElementForm
            onSubmit={handleAddFormElement}
            onClose={props.onClose}
            formElement={dialogFormElement}
          />
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
