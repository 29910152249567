import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import { forwardRef } from 'react';

import { TeamMemberSelectList } from '../team-member-select-list/team-member-select-list.component';
import { useUsersSelectDialog } from './users-select-dialog.hook';
import { UsersSelectDialogProps } from "./users-select-dialog.types";

export const UsersSelectDialog = forwardRef<HTMLButtonElement, React.PropsWithChildren<UsersSelectDialogProps>>((props, ref) => {
    const state = useUsersSelectDialog(props);

    return (<Dialog modal onOpenChange={state.onOpenChange} open={state.open}>
        <>{props.children}</>
        <Dialog.Content className='max-w-[95%] rounded'>
            <Dialog.Header className='border-b py-1'>
                <Dialog.Title className='text-sm text-left'>{props.title}</Dialog.Title>
            </Dialog.Header>
            <Dialog.Description asChild className='flex flex-col gap-3 overflow-hidden'>
                {open && <TeamMemberSelectList
                    unassignedChecked={undefined}
                    loanRoles={props.loanRoles}
                    lenderId={props.lenderId}
                    mode="COMPANY"
                    onSelect={state.onUsersSelected}
                    assignedList={state.assignedList}
                />}
            </Dialog.Description>
            <Dialog.Footer className='flex-row shrink-0'>
                <Dialog.Close asChild>
                    <Button
                        className="w-24"
                        variant="secondary">Close</Button>
                </Dialog.Close>
                <Button
                    disabled={state.loading}
                    className="w-24 disabled:opacity-50"
                    onClick={state.onAssignClick} >Assign</Button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>)
})

UsersSelectDialog.displayName = 'UsersSelectDialog';
